@import url(https://fonts.googleapis.com/css?family=Cabin+Condensed:500&text=Fork%20me%20on%20GitHub);

.ribbon {
  position: absolute;
  top: 42px;
  width: 200px;
  padding: 1px 0;
  background: #000;
  color: #eee;

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

  z-index: 99;
}

.ribbon.left {
  left: -42px;
  transform: rotate(-45deg);
}

.ribbon.right {
  right: -42px;
  transform: rotate(45deg);
}

.ribbon a,
.ribbon a:visited,
.ribbon a:active,
.ribbon a:hover {
  display: block;
  padding: 1px 0;
  height: 24px;
  line-height: 24px;

  color: inherit;
  text-align: center;
  text-decoration: none;
  font-family: 'Cabin Condensed', sans-serif;
  font-size: 16px;
  font-weight: 500;

  border: 1px solid rgba(255, 255, 255, 0.2);

  text-shadow: 0 0 10px rgba(0, 0, 0, 0.31);
}

.ribbon.black {
  background: #000;
}

.ribbon.red {
  background: #c00;
}

.ribbon.blue {
  background: #09e;
}

.ribbon.green {
  background: #0a0;
}

.ribbon.orange {
  background: #d80;
}

.ribbon.purple {
  background: #c0c;
}

.ribbon.grey {
  background: #888;
}

.ribbon.white {
  background: #eee;
  color: black;
}
.ribbon.white a {
  border: 2px dotted rgba(100, 100, 100, 0.2);
}
