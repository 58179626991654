#container {
  display: flex;
  height: 100vh;
}

#container > * {
  flex: 1;
}

#container div.CodeMirror {
  height: 100vh;
  border: 0;
  box-shadow: 2px 0px 5px 0px rgba(171, 171, 171, 1);
  line-height: 18px;
  font-size: 1.1rem;
  background: #f4f2ed;
}

@media (max-width: 413px) {
  #container {
    flex-direction: column;
  }

  #container div.CodeMirror {
    height: 50vh;
  }
}

#container #output {
  padding: 0 15px 15px 15px;
  overflow: auto;
}

/* Codemirror */
.CodeMirror-cursor {
  border-left: 1px solid #5c5855;
}

/* Markdown Styles */
h1,
h2 {
  border-bottom: 1px solid #eee;
  padding-bottom: 0.3em;
}

a:link,
a:visited,
a:active {
  text-decoration: none;
  color: #4078c0;
}

a:hover {
  text-decoration: underline;
}

blockquote {
  padding: 0 1em;
  color: #777;
  border-left: 0.25em solid #ddd;
  margin-left: 0 !important;
}

table {
  border-collapse: collapse;
}

table th,
table td {
  border: 1px solid #ddd;
  padding: 6px 13px;
}

tbody tr:nth-child(even) {
  background-color: #f8f8f8;
}

li {
  padding: 2px;
}

/* Emojify */
.emoji {
  width: 1.4em;
  height: 1.4em;
  display: inline-block;
  margin-top: -12px;
}

/* File Input Upload */
#fileInput {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

#fileInput + label {
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  position: absolute;
  z-index: 99;
  bottom: 1%;
  right: 1%;
}

#fileInput:focus + label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}

.inputfile + label * {
  pointer-events: none;
}

#fileInput + label svg {
  margin-top: -0.25em;
  margin-right: 0.25em;
}

#fileInput + label figure {
  width: 50px;
  height: 50px;
  background-color: #d3394c;
  position: relative;
  padding: 10px;
  margin: 0;
  border-radius: 50%;
}

#fileInput:focus + label figure,
#fileInput + label:hover figure {
  background-color: #722040;
}

#fileInput + label svg {
  width: 100%;
  height: 100%;
  fill: #f1e5e6;
}
