$light-blue: #2d72bc;
$seafoam-green: #61c69d;
$dark-blue: #062F4F;
$dark-earth: #34152c;
$dark-teal: #303f39;
$yellow-accent: #FBEEC1;

$header-background: $light-blue;
$header-color: white;
$header-shadow-color: #61c69d;

$blog-left-nav-background-color: $light-blue;
$blog-right-nav-background-color: $light-blue;

$navigation-active-background-color: $dark-blue;

$post-header-background: #ffffff;
$post-header-color: #3a3d3f;

$blog-footer-background: $light-blue;
$background: white;
$text-color: black;

$blog-skinny-width: 52rem;


#logo {
  width: 100%;
  display: block;

  img {
    max-width: 100%;
    max-height: 20em;
    margin: 2rem auto;
    display: block;
  }

  #logo-subtext {
    text-align: center;
    display: block;
    margin: 1em;
  }
}

.App {
  background-color: $background;
  color: $text-color;
}

.App-header {
  background-color: $header-background;
  min-height: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 4rem;
  color: $header-color;
  padding-left: 1rem;

  h1 {
    font-family: 'GreatVibesRegular', serif;
    font-weight: normal;
    font-size: 4rem;
    margin: 0;
  }

  h6 {
    font-size: 1.5rem;
    font-size: 0.75rem;
    font-style: italic;
    font-weight: normal;
    margin: 1rem 0rem 0.25rem 0rem;
  }

}

.App-footer {
  background-color: $blog-footer-background;
  min-height: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 4rem;
  color: white;
}

.blog-container {
  display: grid;
  grid-column-gap: 1rem;
  margin-top: 1rem;
  margin-bottom:1rem;

  .spacer-left {
    grid-column-start: 1;
    grid-column-end: 5;
  }


  .spacer-right {
    grid-column-start: 5;
    grid-column-end: 5;
  }

  .blog-left-column {
    grid-column-start: 2;
    grid-column-end: 2;
    background-color: $blog-left-nav-background-color;
    min-width: 10rem;
    min-height: 20rem;
  }

  .blog-right-column {
    grid-column-start: 4;
    grid-column-end: 4;
    background-color: $blog-right-nav-background-color;
    min-width: 10rem;
    min-height: 20rem;
  }

  @media screen and (max-width: $blog-skinny-width) {
    .spacer-left, .spacer-right, .blog-right-column, .blog-left-column {
      display: none;
    }
    .blog-content {
      max-width: 100%
    }
  }


  .blog-content {
    margin: auto;
    grid-column-start: 3;
    grid-column-end: 3;
    max-width: 50rem;


    .blog-post {
      box-shadow: 0 4px 8px 0 $dark-teal, 0 6px 0 white;
      padding: 1rem;

    }

    @media screen and (max-width: $blog-skinny-width) {
      .blog-post {
        margin-bottom: 1rem;
      }
    }
  }
}

@media screen and (max-width: $blog-skinny-width) {
  .blog-container {
    display: inline-block;
    grid-gap: 0rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
}


.navigation-bar {
  width: 100%;
  background-color: $header-background;

  .navigation-button {
    display: inline-block;
    background-color: $header-background;
    color: white;
    padding: 1rem;
    text-align: center;
    text-decoration: none;
  }

  .active {
    background-color: $navigation-active-background-color;
  }
}

.temporary-app-footer {
  background-color: $dark-blue;
}

.dark-blue {
  display: inline-block;
  font-size: 0.01em;
  width: 1rem;
  height: 1rem;
  background-color: $dark-blue;
}

.blue-accent {
  display: inline-block;
  margin-bottom: 1rem;
  margin-left: 1rem;
  font-size: 0.01em;
  width: 1rem;
  height: 1rem;
  background-color: $light-blue;
}

.green-accent {
  margin-bottom: 1rem;
  display: inline-block;
  font-size: 0.01em;
  width: 1rem;
  height: 1rem;
  background-color: $seafoam-green;
}

.accent-1 {
  margin-bottom: 1rem;
  display: inline-block;
  font-size: 0.01em;
  width: 1rem;
  height: 1rem;
  background-color: $dark-teal;
}

.accent-2 {
  margin-bottom: 1rem;
  display: inline-block;
  font-size: 0.01em;
  width: 1rem;
  height: 1rem;
  background-color: $dark-earth;
}

.accent-3 {
  margin-bottom: 1rem;
  display: inline-block;
  font-size: 0.01em;
  width: 1rem;
  height: 1rem;
  background-color: $yellow-accent;
}
