@import url(https://fonts.googleapis.com/css?family=Cabin+Condensed:500&text=Fork%20me%20on%20GitHub);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#logo {
  width: 100%;
  display: block; }
  #logo img {
    max-width: 100%;
    max-height: 20em;
    margin: 2rem auto;
    display: block; }
  #logo #logo-subtext {
    text-align: center;
    display: block;
    margin: 1em; }

.App {
  background-color: white;
  color: black; }

.App-header {
  background-color: #2d72bc;
  min-height: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 4rem;
  color: white;
  padding-left: 1rem; }
  .App-header h1 {
    font-family: 'GreatVibesRegular', serif;
    font-weight: normal;
    font-size: 4rem;
    margin: 0; }
  .App-header h6 {
    font-size: 1.5rem;
    font-size: 0.75rem;
    font-style: italic;
    font-weight: normal;
    margin: 1rem 0rem 0.25rem 0rem; }

.App-footer {
  background-color: #2d72bc;
  min-height: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 4rem;
  color: white; }

.blog-container {
  display: grid;
  grid-column-gap: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem; }
  .blog-container .spacer-left {
    grid-column-start: 1;
    grid-column-end: 5; }
  .blog-container .spacer-right {
    grid-column-start: 5;
    grid-column-end: 5; }
  .blog-container .blog-left-column {
    grid-column-start: 2;
    grid-column-end: 2;
    background-color: #2d72bc;
    min-width: 10rem;
    min-height: 20rem; }
  .blog-container .blog-right-column {
    grid-column-start: 4;
    grid-column-end: 4;
    background-color: #2d72bc;
    min-width: 10rem;
    min-height: 20rem; }
  @media screen and (max-width: 52rem) {
    .blog-container .spacer-left, .blog-container .spacer-right, .blog-container .blog-right-column, .blog-container .blog-left-column {
      display: none; }
    .blog-container .blog-content {
      max-width: 100%; } }
  .blog-container .blog-content {
    margin: auto;
    grid-column-start: 3;
    grid-column-end: 3;
    max-width: 50rem; }
    .blog-container .blog-content .blog-post {
      box-shadow: 0 4px 8px 0 #303f39, 0 6px 0 white;
      padding: 1rem; }
    @media screen and (max-width: 52rem) {
      .blog-container .blog-content .blog-post {
        margin-bottom: 1rem; } }

@media screen and (max-width: 52rem) {
  .blog-container {
    display: inline-block;
    grid-gap: 0rem;
    margin-left: 1rem;
    margin-right: 1rem; } }

.navigation-bar {
  width: 100%;
  background-color: #2d72bc; }
  .navigation-bar .navigation-button {
    display: inline-block;
    background-color: #2d72bc;
    color: white;
    padding: 1rem;
    text-align: center;
    text-decoration: none; }
  .navigation-bar .active {
    background-color: #062F4F; }

.temporary-app-footer {
  background-color: #062F4F; }

.dark-blue {
  display: inline-block;
  font-size: 0.01em;
  width: 1rem;
  height: 1rem;
  background-color: #062F4F; }

.blue-accent {
  display: inline-block;
  margin-bottom: 1rem;
  margin-left: 1rem;
  font-size: 0.01em;
  width: 1rem;
  height: 1rem;
  background-color: #2d72bc; }

.green-accent {
  margin-bottom: 1rem;
  display: inline-block;
  font-size: 0.01em;
  width: 1rem;
  height: 1rem;
  background-color: #61c69d; }

.accent-1 {
  margin-bottom: 1rem;
  display: inline-block;
  font-size: 0.01em;
  width: 1rem;
  height: 1rem;
  background-color: #303f39; }

.accent-2 {
  margin-bottom: 1rem;
  display: inline-block;
  font-size: 0.01em;
  width: 1rem;
  height: 1rem;
  background-color: #34152c; }

.accent-3 {
  margin-bottom: 1rem;
  display: inline-block;
  font-size: 0.01em;
  width: 1rem;
  height: 1rem;
  background-color: #FBEEC1; }

/*github.com style (c) Vasily Polovnyov <vast@whiteants.net>*/

pre code {
  display: block;
  overflow-x: auto;
  padding: 0.5em;
  color: #333;
  background: #f7f7f7;
  border-radius: 3px;
}

p code {
  background: #f5f5f5;
  border-radius: 3px;
  padding: 2px;
}

.hljs-comment,
.hljs-quote {
  color: #998;
  font-style: italic;
}

.hljs-keyword,
.hljs-selector-tag,
.hljs-subst {
  color: #333;
  font-weight: bold;
}

.hljs-number,
.hljs-literal,
.hljs-variable,
.hljs-template-variable,
.hljs-tag .hljs-attr {
  color: #008080;
}

.hljs-string,
.hljs-doctag {
  color: #d14;
}

.hljs-title,
.hljs-section,
.hljs-selector-id {
  color: #900;
  font-weight: bold;
}

.hljs-subst {
  font-weight: normal;
}

.hljs-type,
.hljs-class .hljs-title {
  color: #458;
  font-weight: bold;
}

.hljs-tag,
.hljs-name,
.hljs-attribute {
  color: #000080;
  font-weight: normal;
}

.hljs-regexp,
.hljs-link {
  color: #009926;
}

.hljs-symbol,
.hljs-bullet {
  color: #990073;
}

.hljs-built_in,
.hljs-builtin-name {
  color: #0086b3;
}

.hljs-meta {
  color: #999;
  font-weight: bold;
}

.hljs-deletion {
  background: #fdd;
}

.hljs-addition {
  background: #dfd;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}

.ribbon {
  position: absolute;
  top: 42px;
  width: 200px;
  padding: 1px 0;
  background: #000;
  color: #eee;

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

  z-index: 99;
}

.ribbon.left {
  left: -42px;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.ribbon.right {
  right: -42px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.ribbon a,
.ribbon a:visited,
.ribbon a:active,
.ribbon a:hover {
  display: block;
  padding: 1px 0;
  height: 24px;
  line-height: 24px;

  color: inherit;
  text-align: center;
  text-decoration: none;
  font-family: 'Cabin Condensed', sans-serif;
  font-size: 16px;
  font-weight: 500;

  border: 1px solid rgba(255, 255, 255, 0.2);

  text-shadow: 0 0 10px rgba(0, 0, 0, 0.31);
}

.ribbon.black {
  background: #000;
}

.ribbon.red {
  background: #c00;
}

.ribbon.blue {
  background: #09e;
}

.ribbon.green {
  background: #0a0;
}

.ribbon.orange {
  background: #d80;
}

.ribbon.purple {
  background: #c0c;
}

.ribbon.grey {
  background: #888;
}

.ribbon.white {
  background: #eee;
  color: black;
}
.ribbon.white a {
  border: 2px dotted rgba(100, 100, 100, 0.2);
}

#container {
  display: flex;
  height: 100vh;
}

#container > * {
  flex: 1 1;
}

#container div.CodeMirror {
  height: 100vh;
  border: 0;
  box-shadow: 2px 0px 5px 0px rgba(171, 171, 171, 1);
  line-height: 18px;
  font-size: 1.1rem;
  background: #f4f2ed;
}

@media (max-width: 413px) {
  #container {
    flex-direction: column;
  }

  #container div.CodeMirror {
    height: 50vh;
  }
}

#container #output {
  padding: 0 15px 15px 15px;
  overflow: auto;
}

/* Codemirror */
.CodeMirror-cursor {
  border-left: 1px solid #5c5855;
}

/* Markdown Styles */
h1,
h2 {
  border-bottom: 1px solid #eee;
  padding-bottom: 0.3em;
}

a:link,
a:visited,
a:active {
  text-decoration: none;
  color: #4078c0;
}

a:hover {
  text-decoration: underline;
}

blockquote {
  padding: 0 1em;
  color: #777;
  border-left: 0.25em solid #ddd;
  margin-left: 0 !important;
}

table {
  border-collapse: collapse;
}

table th,
table td {
  border: 1px solid #ddd;
  padding: 6px 13px;
}

tbody tr:nth-child(even) {
  background-color: #f8f8f8;
}

li {
  padding: 2px;
}

/* Emojify */
.emoji {
  width: 1.4em;
  height: 1.4em;
  display: inline-block;
  margin-top: -12px;
}

/* File Input Upload */
#fileInput {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

#fileInput + label {
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  position: absolute;
  z-index: 99;
  bottom: 1%;
  right: 1%;
}

#fileInput:focus + label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}

.inputfile + label * {
  pointer-events: none;
}

#fileInput + label svg {
  margin-top: -0.25em;
  margin-right: 0.25em;
}

#fileInput + label figure {
  width: 50px;
  height: 50px;
  background-color: #d3394c;
  position: relative;
  padding: 10px;
  margin: 0;
  border-radius: 50%;
}

#fileInput:focus + label figure,
#fileInput + label:hover figure {
  background-color: #722040;
}

#fileInput + label svg {
  width: 100%;
  height: 100%;
  fill: #f1e5e6;
}

